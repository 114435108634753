import React from 'react';
import ReactHintFactory from 'react-hint';

const ReactHint = ReactHintFactory(React);

// To add tooltip to any element, just add "data-tooltip={"content"}
// to its component. Example: <a data-tooltip="content">hover me</a>
const Tooltip = () => <ReactHint autoPosition events attribute="data-tooltip" delay={100} />;

export default Tooltip;
