/**
 * This file includes additional transforms for body blocks.
 * It's assumed that it will add only functions / callbacks to the blocks.
 * It's also assumed, that normal transforms have been called before
 * calling post transforms.
 *
 * The reason behind it is size of HTML page source. Normally if you let
 * Next.js store all the response from Drupal backend to return all the data
 * in getInitialProps(), then the size of HTML page will be REALLY huge.
 * Therefore we came up with an idea to transform the Drupal output before
 * getInitialProps() returns the data. However, given that Next.js puts
 * all the content into page source using JSON.Stringify(), it is not
 * possible to return functions from getInitialProps(), therefore we have to
 * attach those functions additionally on the client side.
 */
import Router from 'next/router';
import fetch from './fetch.js';
import * as field from './transforms.fields';
import { mustBeNumber, required, minValue, validEmail } from './formFieldValidators';
import { parseOnlyDigits } from './formFieldParsers';
import { SD, RG } from './constants';
import { getDonationFormUrl, getImpact, getPageUrl } from './donationForm';
import transformListingItems from './transforms.listing_content_overview';
import { moneyHandleClick } from './gtm';
import { toast } from 'react-toastify';

const debug = require('debug')('cw:postTransforms.blocks.js');

const getSubmitHandlerForMoneyHandles = (block, blockName) => {
  const transformedBlock = Object.assign(block);
  const { appeal, isShallow } = block.unprocessed;
  const appealTitle = field.getTextValue(appeal, 'title');
  const donationTypes = field.getArrayValue(appeal, 'field_donation_type');
  let recurrence;
  if (
    transformedBlock.useMonthlyDonation ||
    (donationTypes.length && donationTypes[0].value === 'recurring_donation')
  ) {
    recurrence = 'monthly';
  } else {
    recurrence = 'single';
  }

  transformedBlock.onSubmit = ({ value: amount, position }) => {
    // Send money handle click to GA.
    moneyHandleClick(position, blockName, appealTitle, amount, recurrence);

    const url = getDonationFormUrl(appeal, { amount, type: recurrence === 'single' ? SD : RG });
    return Router.push(url.href, url.as, { shallow: isShallow });
  };

  return transformedBlock;
};

const getHeroWithDonationWidgetPostTransgorms = (block) => {
  // The difference from hero_money_handles_form postransform is
  // how we're checking shallow routing. For hero_money_handles_form
  // we use shallow routing if appeals are not configured on the body
  // block level. For this block we use shallow routing if the
  // configured appeal and the page the block is placed on are the same.
  const transformedBlock = Object.assign(block);
  const { singleAppeal, monthlyAppeal, pageId } = block.unprocessed;

  transformedBlock.onSubmit = (values) => {
    const formId = values.formId;
    const type = values[`${formId}_donation_type`];
    const position =
      !values[`${formId}_money_handle`] || !values[`${formId}_money_handle`][type]
        ? 4
        : parseInt(values[`${formId}_money_handle`][type], 10) + 1;
    const amount = parseInt(values[`${formId}_amount`][type], 10);
    const appeal = type === 'recurring_donation' ? monthlyAppeal : singleAppeal;
    const isShallow = field.getNumberValue(appeal, 'nid') === pageId;

    let currentImpact = null;
    if (
      type === SD &&
      transformedBlock.impactsSingleDonation &&
      transformedBlock.impactsSingleDonation.length
    ) {
      currentImpact = getImpact(amount, transformedBlock.impactsSingleDonation);
    }

    if (
      type === RG &&
      transformedBlock.impactsMonthlyDonation &&
      transformedBlock.impactsMonthlyDonation.length
    ) {
      currentImpact = getImpact(amount, transformedBlock.impactsMonthlyDonation);
    }

    const impact = currentImpact
      ? currentImpact.image
        ? 'with_image'
        : 'text_only'
      : 'not_presented';

    // Send money handle click to GA.
    // See https://www.pivotaltracker.com/story/show/174597400 for details.
    moneyHandleClick(
      position,
      'Hero with donation widget',
      field.getTextValue(appeal, 'title'),
      amount,
      type === RG ? 'monthly' : 'single',
      type === RG ? block.preselectedMonthly + 1 : block.preselectedSingle + 1,
      impact,
    );

    const url = getDonationFormUrl(appeal, { amount, type });
    return Router.push(url.href, url.as, { shallow: isShallow });
  };

  return transformedBlock;
};

export default class PostTransformBlocks {
  getProps = {
    /**
     * Handles Appeals Hero block.
     */
    appeals_hero: (block) => {
      const transformedBlock = Object.assign(block);
      const { minAmount, isShallow, appeal } = block.unprocessed;

      if (block.optionOther) {
        transformedBlock.optionOther.inputValidators = [
          required(),
          mustBeNumber(),
          minValue(minAmount || 1),
        ];

        transformedBlock.optionOther.isRequired = true;

        transformedBlock.optionOther.inputParsers = [parseOnlyDigits];
      }

      transformedBlock.onSubmit = ({ value: amount, recurrence }) => {
        const url = getDonationFormUrl(appeal, { amount, type: recurrence === 'single' ? SD : RG });
        return Router.push(url.href, url.as, { shallow: isShallow });
      };

      return transformedBlock;
    },

    /**
     * Handles Money Handles block.
     */
    money_handles_block: (block) => getSubmitHandlerForMoneyHandles(block, 'Money Handles'),

    /**
     * Handles Standalone Money Handles block.
     */
    standalone_money_handles: (block) =>
      getSubmitHandlerForMoneyHandles(block, 'Standalone Money Handles'),

    /**
     * Handles Standalone Money Handles with images block.
     */
    standalone_money_handles_image: (block) =>
      getSubmitHandlerForMoneyHandles(block, 'Standalone Money Handles with images'),

    /**
     * Handles Money handles for inline checkout block.
     */
    money_handles_inline_checkout: (block) => {
      const transformedBlock = Object.assign(block);
      const { page, isShallow } = block.unprocessed;
      const blockName = 'Money handles for inline checkout';
      const pageTitle = field.getTextValue(page, 'title');
      const recurrence = 'single';
      const pageType = field.getTextValue(page, 'entity_bundle');

      transformedBlock.onSubmit = (amount, position) => {
        // Send money handle click to GA.
        moneyHandleClick(position, blockName, pageTitle, amount, recurrence);

        const url =
          pageType === 'appeal'
            ? getDonationFormUrl(page, { amount, type: SD })
            : getPageUrl(page, { amount, type: SD });
        return Router.push(url.href, url.as, { shallow: isShallow });
      };

      return transformedBlock;
    },

    /**
     * Handles Donation Widget block.
     */
    donation_widget: (block) => {
      const transformedBlock = Object.assign(block);
      const { appeal, isShallow } = block.unprocessed;
      const appealTitle = field.getTextValue(appeal, 'title');
      const donationTypes = field.getArrayValue(appeal, 'field_donation_type');
      let recurrence;
      if (
        transformedBlock.useMonthlyDonation ||
        (donationTypes.length && donationTypes[0].value === 'recurring_donation')
      ) {
        recurrence = 'monthly';
      } else {
        recurrence = 'single';
      }

      transformedBlock.inputValidators = [mustBeNumber()];
      transformedBlock.inputParsers = [parseOnlyDigits];
      transformedBlock.onSubmit = ({ amount }) => {
        // Send money handle click to GA.
        moneyHandleClick(0, 'Donation widget', appealTitle, amount, recurrence);

        const url = getDonationFormUrl(appeal, { amount, type: recurrence === 'single' ? SD : RG });
        return Router.push(url.href, url.as, { shallow: isShallow });
      };

      return transformedBlock;
    },

    /**
     * Handles Quick Donation Widget block.
     */
    quick_donate_widget: (block) => {
      const transformedBlock = Object.assign(block);
      const { appeal, isShallow } = block.unprocessed;
      const appealTitle = field.getTextValue(appeal, 'title');
      const donationTypes = field.getArrayValue(appeal, 'field_donation_type');
      let recurrence;
      if (
        transformedBlock.useMonthlyDonation ||
        (donationTypes.length && donationTypes[0].value === 'recurring_donation')
      ) {
        recurrence = 'monthly';
      } else {
        recurrence = 'single';
      }

      transformedBlock.inputValidators = [mustBeNumber()];
      transformedBlock.inputParsers = [parseOnlyDigits];
      transformedBlock.onSubmit = ({ amount }) => {
        // Send money handle click to GA.
        moneyHandleClick(0, 'Quick donation widget', appealTitle, amount, recurrence);

        const url = getDonationFormUrl(appeal, { amount, type: recurrence === 'single' ? SD : RG });
        return Router.push(url.href, url.as, { shallow: isShallow });
      };

      return transformedBlock;
    },

    /**
     * Handles Donation Hero block.
     */
    donation_hero: (block) => {
      const transformedBlock = Object.assign(block);
      const { singleAppeal, monthlyAppeal, minAmount, isShallow, entity } = block.unprocessed;

      if (block.optionOther) {
        transformedBlock.optionOther.inputValidators = [
          required(),
          mustBeNumber(),
          minValue(minAmount || 1),
        ];
        transformedBlock.optionOther.isRequired = true;
        transformedBlock.optionOther.inputParsers = [parseOnlyDigits];
      }

      let dataAppealTitle;

      // Money handles for the block can route to checkout page for
      // current appeal page (in case when block was added to Appeal content type)
      // and to checkout page to appeals that were configured in block (in case
      // when block was added to any other content type.
      let appeal;
      if (!singleAppeal && !monthlyAppeal && entity) {
        appeal = entity;
        dataAppealTitle = field.getTextValue(appeal, 'title');
        transformedBlock.onSubmit = ({ value: amount, recurrence, position }) => {
          const preselectedOption =
            recurrence === 'single' ? block.preselectedSingle : block.preselectedMonthly;
          // Send money handle click to GA.
          moneyHandleClick(
            position,
            'Hero with Money Handles',
            dataAppealTitle,
            amount,
            recurrence,
            preselectedOption,
          );

          const url = getDonationFormUrl(appeal, {
            amount,
            type: recurrence === 'single' ? SD : RG,
          });
          return Router.push(url.href, url.as, { shallow: isShallow });
        };
      } else {
        transformedBlock.onSubmit = ({ value: amount, recurrence, position }) => {
          appeal = recurrence === 'single' ? singleAppeal : monthlyAppeal;
          dataAppealTitle = field.getTextValue(appeal, 'title');

          const preselectedOption =
            recurrence === 'single' ? block.preselectedSingle : block.preselectedMonthly;
          // Send money handle click to GA.
          moneyHandleClick(
            position,
            'Hero with Money Handles',
            dataAppealTitle,
            amount,
            recurrence,
            preselectedOption,
          );

          const url = getDonationFormUrl(appeal, {
            amount,
            type: recurrence === 'single' ? SD : RG,
          });
          return Router.push(url.href, url.as);
        };
      }

      return transformedBlock;
    },

    /**
     * Handles Hero With Money Handles Form block.
     */
    hero_money_handles_form: (block) => {
      const transformedBlock = Object.assign(block);
      const { singleAppeal, monthlyAppeal, entity } = block.unprocessed;

      transformedBlock.onSubmit = (values) => {
        const type = values.donation_type;
        const position =
          !values.money_handle || !values.money_handle[type]
            ? 4
            : parseInt(values.money_handle[type], 10) + 1;
        const amount = parseInt(values.amount[type], 10);
        const configuredAppeal = type === 'recurring_donation' ? monthlyAppeal : singleAppeal;
        const appeal = configuredAppeal || entity;

        let currentImpact = null;
        if (
          type === SD &&
          transformedBlock.impactsSingleDonation &&
          transformedBlock.impactsSingleDonation.length
        ) {
          currentImpact = getImpact(amount, transformedBlock.impactsSingleDonation);
        }

        if (
          type === RG &&
          transformedBlock.impactsMonthlyDonation &&
          transformedBlock.impactsMonthlyDonation.length
        ) {
          currentImpact = getImpact(amount, transformedBlock.impactsMonthlyDonation);
        }

        const impact = currentImpact
          ? currentImpact.image
            ? 'with_image'
            : 'text_only'
          : 'not_presented';

        // Send money handle click to GA.
        // See https://www.pivotaltracker.com/story/show/174597400 for details.
        moneyHandleClick(
          position,
          'Hero with Money Handles Form',
          field.getTextValue(appeal, 'title'),
          amount,
          type === RG ? 'monthly' : 'single',
          type === RG ? block.preselectedMonthly + 1 : block.preselectedSingle + 1,
          impact,
        );

        const url = getDonationFormUrl(appeal, { amount, type });
        return Router.push(url.href, url.as, { shallow: !configuredAppeal });
      };

      return transformedBlock;
    },

    /**
     * Handles Hero with donation widget block.
     */
    hero_with_donation_widget: (block) => getHeroWithDonationWidgetPostTransgorms(block),

    /**
     * Handles Hero with donation widget Emergency block.
     */
    hero_with_donation_widget_emerge: (block) => getHeroWithDonationWidgetPostTransgorms(block),

    /**
     * Handles Listing Content Overview block.
     */
    listing_content_overview: (block) => {
      const transformedBlock = Object.assign(block);

      const endpoint = '/rest/list/content';
      const fetchTopic = ({ topic, page }) =>
        new Promise((resolve, reject) => {
          fetch(endpoint, {
            query: {
              _format: 'json',
              items_per_page: 4,
              primary_topic: topic,
              page,
            },
          })
            .then((resp) =>
              resolve({
                items: transformListingItems(field.getArrayValue(resp.body, 'results')),
                page,
              }),
            )
            .catch((error) => {
              debug('%O', debug);
              reject(error);
            });
        });

      transformedBlock.topics = block.topics.map((topic) => ({
        ...topic,
        pager: {
          ...topic.pager,
          onPrevPageClick: ({ currentPage }) =>
            fetchTopic({
              topic: topic.key,
              page: currentPage - 1,
            }),
          onNextPageClick: ({ currentPage }) =>
            fetchTopic({
              topic: topic.key,
              page: currentPage + 1,
            }),
        },
      }));

      transformedBlock.filter.onSubmit = (query) =>
        new Promise((resolve, reject) => {
          fetch(endpoint, {
            query: {
              ...query,
              items_per_page: 12,
              _format: 'json',
            },
          })
            .then((resp) => resolve(transformListingItems(resp.body.results)))
            .catch((error) => {
              debug('%O', debug);
              reject(error);
            });
        });

      return transformedBlock;
    },

    /**
     * Handles Simple Search Form block.
     */
    simple_search_form: (block) => {
      const transformedBlock = Object.assign(block);
      const { redirectURL } = block.unprocessed;

      transformedBlock.onSubmit = ({ text }) => {
        Router.push({
          pathname: redirectURL,
          query: { query: text },
        });
      };

      return transformedBlock;
    },

    /**
     * Handle body blocks inside two columns layout.
     */
    section_with_sidebar: (block) => {
      return {
        ...block,
        mainBlocks: this.transform(block.mainBlocks),
        sidebarBlocks: this.transform(block.sidebarBlocks),
      };
    },
  };

  /**
   * Starting method which runs blocks with post transforms through
   * appropriate callbacks.
   */
  transform(blocks) {
    return blocks.map((block) => {
      if (block && block.blockType && block.blockType in this.getProps) {
        return this.getProps[block.blockType](block);
      }
      return block;
    });
  }
}
