import field from './transforms.fields';
import { sessionStorageGetItem, sessionStorageSetItem } from './web-storage';
import { DEFAULT_COUNTRY_CODE } from './constants';

/**
 * Validates GUIDs like '7bece356-466f-4c3a-860a-7b25b5c2ea02'.
 */
export const isValidGUID = (value) => {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return regex.test(value);
};

/**
 * Stores attribution data in local storage.
 *
 * @param sourceCode
 *  The source code GUID.
 * @param entity
 *  The entity data array.
 * @param blocks
 *  The transformed page blocks array.
 */
export const handleSourceCodeInUrl = (sourceCode, entity, blocks) => {
  if (!isValidGUID(sourceCode)) {
    return;
  }

  const entityBundle = field.getTextValue(entity, 'entity_bundle');

  // Trying to store the appeal ID as well if possible.
  let appealId = null;
  // For an Appeal node getting the appeal ID from an entity right away.
  if (entityBundle === 'appeal') {
    appealId = field.getNumberValue(entity, 'nid');
  }
  // For Landing pages getting the appeal ID from referencing blocks.
  else if (entityBundle === 'page') {
    const landingPageBlockTypes = [
      'hero_money_handles_form',
      'hero_social_ad',
      'inline_donation_form',
    ];
    for (const block of blocks) {
      if (!landingPageBlockTypes.includes(block.blockType) || !block.appealId) {
        continue;
      }

      appealId = block.appealId;
      break;
    }
  }

  sessionStorageSetItem('cw_attribution', `${sourceCode}${appealId ? ';' + appealId : ''}`);
};

export const getAttribution = () => {
  const attributionString = sessionStorageGetItem('cw_attribution');
  if (!attributionString) {
    return null;
  }

  const attributionArray = attributionString.split(';');
  // Make sure the ID is not corrupted.
  if (!isValidGUID(attributionArray[0])) {
    return null;
  }

  const attribution = {
    source_code: attributionArray[0],
    appeal_id: attributionArray[1] ? parseInt(attributionArray[1]) : undefined,
  };

  return attribution;
};

// Compares ID with Appeal IDs of one-off and monthly general donations.
// The IDs should be always synchronized with corresponding IDs in test suite
// in tests/_support/Helper/AcceptanceClass::getAppealsFromApi.
export const isGeneralDonation = (appealId) =>
  DEFAULT_COUNTRY_CODE === 'IE' ? [1175, 1176].includes(appealId) : [1090, 167].includes(appealId);
