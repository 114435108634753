import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import styles from './index.module.scss';

const AlertComponent = () => (
  <div className={styles['alert']}>
    <ToastContainer
      autoClose={5000}
      limit={3}
      position={'top-right'}
      theme={'colored'}
      className={styles['alert__container']}
    />
  </div>
);

export default AlertComponent;
