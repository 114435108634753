/**
 * This file includes additional transforms for global settings.
 * It's assumed that it will add only functions / callbacks to the settings.
 * It's also assumed, that normal transforms have been called before
 * calling post transforms.
 *
 * The reason behind it is size of HTML page source. Normally if you let
 * Next.js store all the response from Drupal backend to return all the data
 * in getInitialProps(), then the size of HTML page will be REALLY huge.
 * Therefore we came up with an idea to transform the Drupal output before
 * getInitialProps() returns the data. However, given that Next.js puts
 * all the content into page source using JSON.Stringify(), it is not
 * possible to return functions from getInitialProps(), therefore we have to
 * attach those functions additionally on the client side.
 */
import { minLength } from './formFieldValidators';

export const header = (props) => {
  const transformedProps = { ...props };

  if (!transformedProps.searchContents) {
    transformedProps.searchContents = {};
  }

  transformedProps.searchContents = { ...transformedProps.searchContents };

  // Add validators for search input.
  transformedProps.searchContents.inputValidators = [minLength(2)];

  // Add the re-direct to the search page for header search dropdown.
  transformedProps.searchContents.onGotoSearch = ({ query }) => {
    window.location.href = `/search${query ? `?query=${query}` : ''}`;
  };

  return transformedProps;
};
