import React, { useRef } from 'react';

import styles from './index.module.scss';

const AccessibilitySkip = () => {
  const linkRef = useRef();

  const onFocus = () => {
    linkRef.current.className = `accessibility-skip ${styles['accessibility-skip']} ${styles['accessibility-skip__showing']}`;
  };

  const onBlur = () => {
    linkRef.current.className = `accessibility-skip ${styles['accessibility-skip']}`;
  };

  const skipToMain = (event) => {
    // Make sure that tabbing through the link doesn't fire the onClick.
    if (event.keyCode === 9) {
      return;
    }

    event.preventDefault();

    // Find the first header on a page, and on pages with Hero videos, respectively.
    const anchor = document.querySelector('h1') || document.querySelector('h2');

    // Scroll to the top of the nearest page title, with a small offset to show breadcrumbs.
    window.scrollTo(0, anchor.getBoundingClientRect().top - 28);
    document.querySelector('.accessibility-skip').blur();

    // Now start the tab behaviour from the anchor element
    // this avoids us having to put # ids on all potential anchors.
    anchor.setAttribute('tabIndex', '0');
    anchor.focus();
  };

  return (
    <a
      className={`accessibility-skip ${styles['accessibility-skip']}`}
      ref={linkRef}
      data-component="accessibility-skip"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      href="#main"
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={skipToMain}
      onClick={skipToMain}
    >
      Skip to main content
    </a>
  );
};

export default AccessibilitySkip;
