/**
 * Helper function to compose multiple parsers into one.
 *
 * @param parsers
 *   Array of parser functions.
 */
export const composeParsers =
  (...parsers) =>
  (value) =>
    parsers.reduce((previousValue, parser) => parser(previousValue), value);

/**
 * Form field value parser.
 * Allows users to input only digits into the field.
 *
 * @param value
 *   Raw form field value.
 */
export const parseOnlyDigits = (value) => value && value.replace(/[^\d]/g, '');

/**
 * Form field value parser.
 * Allows users to input phone number only by mask.
 *
 * @param value
 *   Raw form field value.
 */
export const parsePhone = (value) => value && value.replace(/[^\d+\s\-().]/g, '');

/**
 * Form field value parser.
 * Allows users to input only certain amount of characters.
 *
 * @param limit
 *   Length limit for value.
 */
export const parseLengthLimit = (limit) => (value) => value && value.slice(0, limit);

/**
 * Form field value parser.
 * Disallows users to input spaces.
 *
 * @param value
 *   Raw form field value.
 */
export const parseSpaceless = (value) => value && value.replace(/\s/g, '');

/**
 * Form field value parser.
 * Helps users to input credit card expiration date.
 *
 * @param value
 *   Raw form field value.
 */
export const parseCreditCardExpiration = (previousValue) => (value) => {
  if (!value) {
    return undefined;
  }
  // Grab only numeric symbols.
  let parsedValue = value.replace(/[^\d]/g, '');

  // If there was just one digit input so far - month can start from 0 or 1.
  if (parsedValue.length === 1 && parsedValue >= 2) {
    parsedValue = `0${value}`;
  }

  // If there were just 2 digits so far - month is limited by 12.
  if (parsedValue.length === 2 && parsedValue > 12) {
    parsedValue = `0${value}`;
  }

  // User deleted slash symbol attempting to modify month.
  // In order to hide slash we need to keep only one symbol in the field.
  if (previousValue === `${value}/`) {
    return value[0];
  }

  // Add slash in a proper place in the string.
  if (parsedValue.length >= 2) {
    parsedValue = `${parsedValue.slice(0, 2)}/${parsedValue.slice(2, 4)}`;
  }

  return parsedValue;
};
