import * as field from './transforms.fields';
import htmlDecode from './htmlDecode';

/**
 * Helper for returning the component data structure for the BBTeaserWithPager items.
 */
const transform = (items = []) =>
  items.map((item) => ({
    title: htmlDecode(String(item.title)),
    description: item.description,
    // Image is given as URL in a non-standard string, so can't use existing transforms
    image: field.getPicture(
      {
        field_media_image: [
          {
            image_styles: { original: htmlDecode(String(item.image_original)) },
            alt: item.image_alt || '',
          },
        ],
      },
      'field_media_image',
      {
        'min-xs': {
          '1x': 'content_overview',
        },
      },
      'content_overview'
    ),
    nextLink: field.getEntityURL({
      url: { url: item.url, entity_type: 'node', entity_bundle: item.type },
    }),
    type: item.type_label || '',
    timestamp: parseInt(item.created, 10),
  }));

export default transform;
